<template>
  <div>
    <!-- <b-row class="banner-margin"> -->
    <!-- <div
      class="d-flex justify-content-center col-12 text-center"
      :class="
        !is_mobilesize
          ? 'pt-1 pb-1 dekstop-banner'
          : 'pt-2 pb-1 mobile-header-banner'
      "
    >
      <b-link href="https://bit.ly/3KNgeZ6" target="_blank">
        <b-img
          src="@/assets/images/banners/LIBERO.gif"
          :class="!is_mobilesize ? 'desktop-banner' : 'mobile-banner'"
        />
      </b-link>
    </div> -->
    <!-- <div class="d-flex justify-content-center col-12 text-center pt-1">
      <b-link href="https://bit.ly/3Cp8Ayw" target="_blank">
        <b-img
          src="@/assets/images/banners/totoroinu.gif"
          :class="!is_mobilesize ? 'desktop-banner' : 'mobile-banner'"
        />
      </b-link>
    </div> -->

    <!-- <div class="d-flex justify-content-center col-12 text-center pt-1">
      <b-carousel id="carousel-interval" :interval="4000">
        <b-link href="https://bit.ly/3Cp8Ayw" target="_blank">
          <b-carousel-slide
            :class="!is_mobilesize ? 'desktop-banner' : 'mobile-banner'"
            :img-src="require('@/assets/images/banners/totoroinu.gif')"
          >
          </b-carousel-slide>
        </b-link>
      </b-carousel>
    </div> -->
    <!-- </b-row> -->
  </div>
</template>

<script>
// import { mixinList } from "@/mixins/mixinList";
// import { BLink, BImg } from "bootstrap-vue";
// import AddHere from "@/@core/components/AddHere.vue";

export default {
  // mixins: [mixinList],
  components: {
    // BRow,
    // BLink,
    // BImg,
    // AddHere,
    // BCarousel,
    // BCarouselSlide,
  },
  data() {
    return {};
  },
};
</script>

<style>
@media (max-width: 1024px) {
  .mobile-header-banner {
    margin-bottom: 40px !important;
  }
}
.dekstop-banner {
  margin-bottom: 30px !important;
}
.mobile-header-banner {
  margin-top: -100px;
}
.b-carousel {
  height: "140px" !important;
}
.iframe-desktop {
  width: 1024px;
  height: 116px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
.iframe-mobile {
  width: 350px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
.desktop-banner {
  width: 1024px !important;
}
.mobile-banner {
  width: 375px !important;
}
.top-padding {
  margin-top: 7px;
}
</style>