<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-center col-12 text-center pt-1">
        <h3 class="partners">Strategic partners</h3>
      </div>
      <div class="d-flex justify-content-center col-12 text-center pb-1">
        <b-row>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link
                href="https://www.pinksale.finance/#/?chain=BSC"
                target="_blank"
              >
                <b-img
                  src="@/assets/images/patners/pinkswap.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Pinksale</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link
                href="https://linktr.ee/sersinvestmentgroup"
                target="_blank"
              >
                <b-img
                  src="@/assets/images/patners/sersinvestmentgroup.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Sers Investment Group</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link
                href="https://dxsale.network/"
                target="_blank"
              >
                <b-img
                  src="@/assets/images/patners/DX-black_.svg"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Dxsale</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link
                href="https://gempad.app/"
                target="_blank"
              >
                <b-img
                  src="@/assets/images/patners/gempad.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Gempad</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link
                href="https://pandasale.finance/"
                target="_blank"
              >
                <b-img
                  src="@/assets/images/patners/logopanda.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Panda Sale</span>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex justify-content-center col-12 text-center pt-1">
        <h3 class="partners">Project Partners</h3>
      </div>
      <div
        class="
          d-flex
          justify-content-center
          col-12
          text-center
          pb-2
        "
      >
        <b-row class="partner-row">
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://www.matadortoken.com/" target="_blank">
                <b-img
                  src="@/assets/images/patners/matador.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Matador</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://www.rugscan.finance/" target="_blank">
                <b-img
                  src="@/assets/images/patners/rugscan.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Rugscan</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://popcat.site/" target="_blank">
                <b-img
                  src="@/assets/images/patners/popcat.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Popcat</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://thoreum.finance/" target="_blank">
                <b-img
                  src="@/assets/images/patners/thoreum.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Thoreum</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://kiradoge.com/" target="_blank">
                <b-img
                  src="@/assets/images/patners/kiradoge.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Kiradoge</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://epichero.io/" target="_blank">
                <b-img
                  src="@/assets/images/patners/epichero.jpg"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Epichero</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://www.konigswap.com/" target="_blank">
                <b-img
                  src="@/assets/images/patners/koingswap.jpg"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Konigswap</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://dappsy.io/" target="_blank">
                <b-img
                  src="@/assets/images/patners/dappsy.jpg"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Dappsy</span>
          </b-col>
          <b-col align-self="center">
            <div class="px-2 pt-2">
              <b-link href="https://contracto.org/" target="_blank">
                <b-img
                  src="@/assets/images/patners/contracto.png"
                  class="partners-banner"
                />
              </b-link>
            </div>
            <span class="partner-name">Contracto</span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { BLink, BImg, BRow, BCol, BCard } from "bootstrap-vue";
export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BCard,
  },
  data() {
    return {
      strategic_partners: [
        {
          img: "./../assets/images/banners/metahangrygames_header.gif",
          link: "https://www.pinksale.finance/#/?chain=BSC",
        },
      ],
      project_partners: [
        {
          img: "./../../src/assets/images/patners/matador.png",
          link: "https://www.matadortoken.com/",
        },
      ],
    };
  },
};
</script>

<style>
.partners-banner {
  width: 50px !important;
}
.partners {
  color: aquamarine !important;
}
.partner-name {
  font-size: 12px;
  font-weight: 900;
}
@media (max-width: 1024px) {
  .partner-row {
    margin-left: -3rem !important;
  }
}
</style>