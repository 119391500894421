<template>
  <div>
    <b-row class="pb-1" v-if="table_name == 'all-best'">
      <b-col md="9"></b-col>
      <b-col md="3">
        <div class="d-flex justify-content-end col-12 text-end">
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Search your coin"
              @input="searchMe(filter)"
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <b-card v-if="tableData" no-body class="card-company-table">
      <b-progress v-if="loading" :max="max" height="3px" :striped="true" :animated="true">
        <b-progress-bar :value="value" variant="success"> </b-progress-bar>
      </b-progress>
      
      <b-table
        id="table-crypto"
        hover
        :items="tableData"
        responsive
        :fields="
          !is_mobilesize
            ? table_name != 'audited'
              ? table_name == 'most-trust'
                ? fields_audited
                : fields
              : fields_audited
            : table_name == 'calender'
            ? fields_calender
            : table_name != 'audited'
            ? fields_mobile
            : fields_audited_mobile
        "
        :sort-by="table_name != 'today-best' ? sortBy : ''"
        :sort-desc.sync="sortDesc"
        class="mb-0"
        :tbody-transition-props="transProps"
        @row-clicked="viewDetails"
        :busy="loading"
      >
        <!-- company -->
        <!-- <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template> -->
        <template #cell(no)="data">
          <span class="pl-1">
            {{ data.index + 1 }}
          </span>
        </template>
        <template #cell(name)="data">
          <div class="pl-5 pb-1" v-if="table_name == 'today-best'">
            <b-badge v-if="data.item.is_today_promoted == 1" variant="success"
              >Promoted</b-badge
            >
          </div>

          <div class="d-flex align-items-center">
            <b-avatar rounded size="45" variant="light-company">
              <b-img-lazy
                center
                fluid
                :src="data.item.logo_link"
                alt="avatar img"
                class="image-size"
            /></b-avatar>
            <div>
              <div class="font-weight-bolder pl-1">
                {{ !is_mobilesize ? data.item.name : data.item.name.slice(0, 8) + ".." }}
              </div>
              <div class="font-small-2 text-muted pl-1" v-if="!is_mobilesize">
                {{ data.item.symbol }}
              </div>
            </div>
            <div class="pl-1 pb-1" v-if="data.item.coinmarketcap != null">
              <b-avatar
                size="20"
                variant="light-company"
                :href="data.item.coinmarketcap"
                target="_blank"
              >
                <b-img-lazy
                  center
                  fluid
                  src="@/assets/images/icons/coinmarketcap.jpeg"
                  alt="avatar img"
                  class="image-size"
              /></b-avatar>
            </div>
            <div class="pl-1 pb-1" v-if="data.item.coingecko != null">
              <b-avatar
                size="20"
                variant="light-company"
                :href="data.item.coingecko"
                target="_blank"
              >
                <b-img-lazy
                  center
                  fluid
                  src="@/assets/images/icons/coingecko.jpeg"
                  alt="avatar img"
                  class="image-size"
              /></b-avatar>
            </div>
          </div>
        </template>

        <!-- actual_market_cap -->
        <template #cell(actual_market_cap)="data">
          <!-- <div class="d-flex align-items-center">
            <span class="font-weight-bolder">
              $ {{ numeral(data.item.actual_market_cap).format("0,0") }}</span
            >
          </div> -->
          <MarketCap :token_address="data.item.bsc_contract_address ? data.item.bsc_contract_address : 'no-address'" :actual_market_cap="data.item.actual_market_cap"  />
        </template>

        <!-- release_date -->
        <template #cell(release_date)="data">
          <div class="d-flex flex-column">
            <span class="font-weight-bolder mb-25">{{
              data.item.release_date | diffForHumans
            }}</span>
          </div>
        </template>

        <!-- actual_price -->
        <template #cell(risk_level)="data">
          <b-badge
            v-if="data.item.risk_level == 'SCAM'"
            v-b-tooltip.hover.v-danger
            title="Stay the fuck away (SCAM)"
            variant="danger"
            >{{ data.item.risk_level }}</b-badge
          >
          <b-badge
            v-if="data.item.risk_level == 'DYOR'"
            v-b-tooltip.hover.v-secondary
            title="Looks Fine (DYOR)"
            variant="secondary"
            >{{ data.item.risk_level }}</b-badge
          >
          <b-badge
            v-else-if="data.item.risk_level == 'Low'"
            v-b-tooltip.hover.v-info
            title="Everything looks good & with a good use case (Low risk)"
            variant="info"
            >{{ data.item.risk_level }}</b-badge
          >
          <b-badge
            v-else-if="data.item.risk_level == 'Medium'"
            v-b-tooltip.hover.v-primary
            title=" Looks okay - and with a normal use case (Medium Risk)"
            variant="primary"
            >{{ data.item.risk_level }}</b-badge
          >
          <b-badge
            v-else-if="data.item.risk_level == 'High'"
            v-b-tooltip.hover.v-warning
            title="HIGH chance to be a scam (DYOR)"
            variant="warning"
            >{{ data.item.risk_level }}</b-badge
          >
          <b-badge
            v-else-if="data.item.risk_level == 'SAFU'"
            v-b-tooltip.hover.v-success
            title="Legit! A strong use case with a great Hype (SAFU)"
            variant="success"
            >{{ data.item.risk_level }}</b-badge
          >
        </template>

        <!-- report -->
        <template #cell(report)="data">
          <div class="d-flex align-items-center align-center">
            <b-button
              v-if="data.item.report_link != null"
              variant="info gradient"
              :class="is_mobilesize ? 'button-class' : 'desktop-button'"
              :href="data.item.report_link"
              target="_blank"
            >
              Audit Report
            </b-button>
            <div v-else class="d-flex align-items-center">
              <span>Pending</span>
            </div>
          </div>
        </template>

        <!-- vote_count -->
        <template #cell(vote_count)="data">
          <div class="d-flex align-items-center">
            <b-button
              :variant="isVoted(data.item.is_voted)"
              @click="castVote(data.item)"
              :class="is_mobilesize ? 'button-class' : 'desktop-button'"
            >
              <div v-if="loading && data.item.id == selectId">
                <b-spinner
                  label="Loading..."
                  class="mr-2 mx-1"
                  style="width: 13px; height: 13px"
                ></b-spinner>
              </div>
              <div v-else>
                {{ table_name == "most-trust" ? "🔥" : "🚀" }}
                {{ data.item.vote_count }}
              </div>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="text-center my-2" v-if="loading">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Loading...</strong>
      </div>
      <div class="text-center pb-1" v-if="!is_show_pagination && total > per_page">
        <b-button
          v-if="!loading"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="flat-secondary"
          @click="changePagination"
          class="font-weight-bolder"
        >
          See More
        </b-button>
        <b-spinner
          v-else
          label="Loading..."
          class="mr-2 mx-1"
          style="width: 13px; height: 13px"
        ></b-spinner>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import Ripple from "vue-ripple-directive";
import MarketCap from "./MarketCap.vue"
// import SearchCoin from "./SearchCoin"
import {
  BCard,
  BTable,
  BAvatar,
  BImgLazy,
  BButton,
  BSpinner,
  BProgress,
  BProgressBar,
  BFormInput,
  BCol,
  BRow,
  BInputGroup,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import numeral from "numeral";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImgLazy,
    BButton,
    BSpinner,
    BProgress,
    BProgressBar,
    BFormInput,
    BCol,
    BRow,
    BInputGroup,
    BBadge,
    MarketCap
    // SearchCoin,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    table_name: {
      type: String,
    },
    total: {
      type: Number,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      token_addrs: [],
      per_page: 20,
      is_show_pagination: false,
      transProps: {
        // Transition name
        name: "flip-list",
      },
      numeral,
      dayjs,
      relativeTime,
      sortBy: "vote_count",
      sortDesc: true,
      fields: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "actual_market_cap", label: "MARKET CAP" },
        { key: "release_date", label: "RELEASED" },
        { key: "risk_level", label: "RISK LEVEL" },
        { key: "vote_count", label: "VOTES" },
      ],
      fields_audited: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "actual_market_cap", label: "MARKET CAP" },
        { key: "release_date", label: "RELEASED" },
        { key: "risk_level", label: "RISK LEVEL" },
        { key: "report", label: "Audit" },
        { key: "vote_count", label: "VOTES" },
      ],
      fields_audited_mobile: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "report", label: "Audit" },
        { key: "vote_count", label: "VOTES" },
      ],
      fields_mobile: [
        { key: "no", label: "NO" },
        { key: "name", label: "NAME" },
        { key: "risk_level", label: "RISK LEVEL" },
        // { key: "release_date", label: "RELEASED" },
        // { key: "actual_price", label: "PRICE" },
        { key: "vote_count", label: "VOTES" },
      ],
      selectId: null,
      value: 0,
      max: 100,
      windowHeight: window.innerWidth,
      filter: null,
    };
  },
  watch: {},
  created() {
    this.dayjs.extend(relativeTime);
  },
  computed: {
    loading() {
      return this.$store.state.loaders.loading;
    },
  },
  methods: {
    searchMe(val) {
      const data = {
        perPage: this.per_page,
        query: val,
      };
      setTimeout(() => {
        this.$store.dispatch("SEARCH_CRYPTO_DATA", data);
      }, 1000);
    },
    changePagination() {
      this.per_page = this.per_page + 20;
      if (this.table_name == "all-best") {
        this.$store.dispatch("FETCH_CRYPTO_DATA", this.per_page);
      } else if (this.table_name == "today-best") {
        this.$store.dispatch("FETCH_TODAY_BEST_CRYPTO_DATA", this.per_page);
      } else if (this.table_name == "audited") {
        this.$store.dispatch("FETCH_AUDITED_CRYPTO_DATA", this.per_page);
      } else if (this.table_name == "pramoted") {
        this.$store.dispatch("FETCH_PROMOTED_CRYPTO_DATA", this.per_page);
      }
    },
    startTimer() {
      let vm = this;
      let timer = setInterval(function () {
        vm.value += 20;
        if (vm.value >= 100) clearInterval(timer);
      }, 100);
    },
    castVote(coin) {
      this.selectId = coin.id;
      const data = {
        coinID: coin.id,
        perPage: this.per_page,
      };
      this.$store.dispatch("CAST_VOTE", data);
    },
    isVoted(isVoted) {
      return isVoted ? "success" : "outline-success";
    },
    viewDetails(coin) {
      this.$router.push({
        path: `/details/${coin.id}`,
        params: {
          id: coin.id,
        },
      });
    },
  },
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null;
      }

      return dayjs(date).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: #0238ea !important;
  }
}
table#table-crypto .flip-list-move {
  transition: transform 1s;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem 0rem;
}
.button-class {
  margin: 0;
  width: 75px;
  font-size: 10px;
}
.desktop-button {
  margin: 0;
  width: 150;
}
.row-background {
  background-color: #f1f1f1;
}
@media (max-width: 1024px) {
  .image-size {
    max-width: 60% !important;
  }
}
</style>

<style>
.dark-layout .table {
    background-color: #0c0d20 !important;
}
</style>
