<template>
  <div>
    <div class="d-flex align-items-center">
        <div v-if="loading">
            <b-spinner
                type="grow"
                label="Loading..."
                class="mr-2 mx-1"
                style="width: 13px; height: 13px"
            ></b-spinner>
        </div>
        <div v-else>
            <span class="font-weight-bolder" v-if="has_token">
                $ {{ numeral(token_data.tokenMarketCap).format("0,0") }}
            </span>
            <span class="font-weight-bolder" v-else>
                $ {{ numeral(actual_market_cap).format("0,0") }}
            </span>
        </div>
        <div v-if="has_token" class="pl-1">
            ⭐
        </div>
            
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import { BSpinner } from "bootstrap-vue";

export default {
  props: {
    token_address: {
      type: String,
    },
    actual_market_cap: {
        type: Number
    }
  },
  components: {
      BSpinner
  },

  data() {
    return {
      token_data: [],
      numeral,
      has_token: false
    };
  },
  computed: {
    // tokenData() {
    //   return this.$store.state.crypto.tokenData;
    // },
    loading() {
      return this.$store.state.loaders.loading;
    },
  },
  mounted() {
    this.getTokenData();
  },
  methods: {
    getTokenData() {
        if (this.token_address != 'no-address') {
            this.$store.dispatch("FETCH_PRICE_DATA", this.token_address).then((res) => {
                this.token_data = res.tokenMetadata;
            });
            this.has_token = true
        } else {
            this.has_token = false
        }
    },
  },
};
</script>

<style></style>
