<template>
  <div>
    <b-row v-if="api_loading" class="match-height">
      <b-col lg="12">
        <div class="d-flex justify-content-center mb-3 col-12 text-center">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row>
    <!-- <socials v-if="is_mobilesize"/> -->
    <!-- <socials2 v-if="!is_mobilesize" :class="is_mobilesize ? 'pr-5': ''"  /> -->
    <div class="d-flex justify-content-center col-12 text-center">
      <head-banner />
    </div>
    <div class="d-flex justify-content-center col-12 text-center">
      <add-here />
    </div>
    <!-- <swap-carousel class="pt-1" /> -->
    <!-- <Popup /> -->
    <!-- <blink-add :cryptoDataTrusted="cryptoDataPromoted" /> -->
    <b-row class="match-height mb-1">
      <b-col lg="12">
        <h3>Promoted coins</h3>
        <small
          >Only audited coins are listed in the paid promotions section.</small
        >
      </b-col>
    </b-row>
    <b-row class="match-height mb-1">
      <b-col lg="12">
        <crypto-table
          :table-data="cryptoDataPromoted"
          :table_name="'pramoted'"
          :total="promoted_total"
        />
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="12">
        <h3>The most trusted coin</h3>
        <small>The most unique & legit coin today</small>
      </b-col>
    </b-row>

    <b-row class="match-height mb-1">
      <b-col lg="12">
        <crypto-table
          :table-data="cryptoDataTrusted"
          :table_name="'most-trust'"
          :total="1"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <Patners />
      </b-col>
    </b-row>

    <b-row v-if="loading" class="match-height">
      <b-col lg="12">
        <div class="d-flex justify-content-center mb-3 col-12 text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row>

    <!-- This tabs content will always be mounted -->
    <b-tabs content-class="mt-1">
      <b-tab :title="!is_mobilesize ? 'All time best' : 'ALL TIME'" class="tab-left-end">
        <small
          >Won't get visibility unless voted, so make sure your community knows
          about it.</small
        >
        <b-row class="match-height mt-1">
          <b-col lg="12">
            <crypto-table
              :table-data="cryptoData"
              :table_name="'all-best'"
              :total="all_total"
            />
          </b-col>
        </b-row>
      </b-tab>
      <!-- This tabs content will not be mounted until the tab is shown -->
      <!-- and will be un-mounted when hidden -->
      <b-tab :title="!is_mobilesize ? 'Today best' : 'TODAY'" lazy>
        <small>Today best performing coins</small>
        <b-row class="match-height mt-1">
          <b-col lg="12">
            <crypto-table
              :table-data="cryptoDataTodayBest"
              :table_name="'today-best'"
              :total="best_total"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="!is_mobilesize ? 'Audited coins' : 'AUDITED'" lazy>
        <small
          >We give 95% assurance to the public that those audited coins are rug
          pull-free and scams will not likely happen. We will keep tracking the
          coins daily and make sure no suspicious activity will occur.</small
        >
        <b-row class="match-height mt-1">
          <b-col lg="12" class="text-center">
            <crypto-table
              :table-data="cryptoDataAuditedCoins"
              :table_name="'audited'"
              :total="audit_total"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        class="tab-class"
        :title="!is_mobilesize ? 'Upcoming Gems' : 'UPCOMING'"
        lazy
      >
        <small
          >Only scanned projects are listed in the upcoming gems section</small
        >
        <b-row class="match-height mt-1">
          <b-col lg="12" class="text-center">
            <crypto-table
              :table-data="cryptoUpcoming"
              :table_name="'upcoming'"
              :total="upcoming_total"
            />
          </b-col>
        </b-row>
        <b-row class="match-height mb-1">
          <b-col lg="12" class="text-center">
            <h3 class="text-center">For upcoming launches</h3>
            <small>Mail to </small> <b>contact@rugfreecoins.com</b>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="!is_mobilesize ? 'Doxxed coins' : 'DOXXED'" lazy>
        <small>Visibility to the doxxed dev projects</small>
        <b-row class="match-height mt-1">
          <b-col lg="12" class="text-center">
            <crypto-table
              :table-data="cryptoDoxxed"
              :table_name="'doxxed'"
              :total="doxxed_total"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-center mb-1 col-12 text-center">
      <iframe
        data-aa="1717027"
        src="//ad.a-ads.com/1717027?size=970x90"
        :class="!is_mobilesize ? 'iframe-desktop' : 'iframe-mobile'"
      ></iframe>
    </div>
    <div class="d-flex justify-content-center mb-3 col-12 text-center">
      <ins
        class="61c49aa52fb953001211468d"
        style="display: inline-block; width: 970px; height: 90px"
      ></ins>
    </div>
    <div class="telegram-popup" align="center" v-if="!is_mobilesize">
      <p>Chat with us.</p>
      <!-- Telegram icon. I like to use font-awesome but dont want to effect your imports too much.-->
      <a
        href="http://t.me/rugfreecoin"
        class="telegram-button-link"
        target="_blank"
        ><div class="telegram-button">
          <p>
            <i class="fab fa-telegram-plane"></i>
            Join our Telegram.
          </p>
        </div></a
      >
    </div>
    <div class="telegram-popup" align="center" v-else>
      <!-- Telegram icon. I like to use font-awesome but dont want to effect your imports too much.-->
      <a
        href="http://t.me/rugfreecoin"
        target="_blank"
        class="telegram-button-link"
        ><div class="telegram-button">
          <p>
            <i class="fab fa-telegram-plane"></i>
          </p></div
      ></a>
    </div>
  </div>
</template>
<script>
!(function () {
  !(function e(n, c, t, o, r, m, s, a) {
    (s = c.getElementsByTagName(t)[0]),
      ((a = c.createElement(t)).async = !0),
      (a.src = "https://" + r[m] + "/js/" + o + ".js"),
      (a.onerror = function () {
        a.remove(), (m += 1) >= r.length || e(n, c, t, o, r, m);
      }),
      s.parentNode.insertBefore(a, s);
  })(
    window,
    document,
    "script",
    "61c49aa52fb953001211468d",
    ["cdn.bmcdn2.com"],
    0
  );
})();
</script>
<script>
import { BRow, BCol, BSpinner, BTabs, BTab } from "bootstrap-vue";
import CryptoTable from "../views/crypto/CryptoTable";
import { mixinList } from "@/mixins/mixinList";
// import SwapCarousel from "@/@core/components/carousel/SwapCarousel.vue";
import AddHere from "@/@core/components/AddHere.vue";
// import Popup from "@/@core/components/carousel/Popup.vue";
import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
// import BlinkAdd from './BlinkAdd.vue'
import Patners from "./Patners.vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    BSpinner,
    CryptoTable,
    BTabs,
    BTab,
    // SwapCarousel,
    HeadBanner,
    Patners,
    // Popup,
    // BLink,
    // BImg,
    // BlinkAdd
    AddHere,
  },
  data() {
    return {
      loading: false,
      is_show_marketing: false,
      active: false,
    };
  },
  computed: {
    cryptoData() {
      return this.$store.state.crypto.cryptoData;
    },
    cryptoDataPromoted() {
      return this.$store.state.crypto.cryptoDataPromoted;
    },
    cryptoDataTodayBest() {
      return this.$store.state.crypto.cryptoDataTodayBest;
    },
    cryptoDataAuditedCoins() {
      return this.$store.state.crypto.cryptoDataAuditedCoins;
    },
    api_loading() {
      return this.$store.state.loaders.apiloading;
    },
    best_total() {
      return this.$store.state.crypto.best_total;
    },
    promoted_total() {
      return this.$store.state.crypto.promoted_total;
    },
    audit_total() {
      return this.$store.state.crypto.audit_total;
    },
    all_total() {
      return this.$store.state.crypto.all_total;
    },
    upcoming_total() {
      return this.$store.state.crypto.upcoming_total;
    },
    cryptoDataTrusted() {
      return this.$store.state.crypto.cryptoDataTrusted;
    },
    cryptoUpcoming() {
      return this.$store.state.crypto.cryptoUpcoming;
    },
    cryptoDoxxed() {
      return this.$store.state.crypto.cryptoDoxxed;
    },
    doxxed_total() {
      return this.$store.state.crypto.doxxed_total;
    },
  },
  methods: {
    closeIcon() {
      this.is_show_marketing = !this.is_show_marketing;
    },
    click() {
      this.active = !this.active;
    },
  },
  created() {
    if (!this.$store.state.crypto.clientIP) {
      this.$store.dispatch("FETCH_CLIENT_IP").then(() => {
        this.$store.dispatch("FETCH_CRYPTO_DATA", 20);
        this.$store.dispatch("FETCH_PROMOTED_CRYPTO_DATA", 20);
        this.$store.dispatch("FETCH_TODAY_BEST_CRYPTO_DATA", 20);
        this.$store.dispatch("FETCH_AUDITED_CRYPTO_DATA", 20);
        this.$store.dispatch("FETCH_MOST_TRUST_DATA", 20);
        this.$store.dispatch("FETCH_UPCOMING_DATA", 20);
        this.$store.dispatch("FETCH_DOXXED_DATA", 20);
      });
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
.telegram-popup {
  height: 130px;
  min-width: 15%;
  width: 200px;
  background-color: #ffffff;

  position: fixed;
  bottom: 7%;
  left: 82%;

  /*round corners*/
  border-radius: 10px;
  /*cool option borders.*/
}
@media (max-width: 1024px) {
  
  .telegram-popup {
    position: fixed;
    bottom: 13%;
    left: 75%;
    width: 10% !important;
    /*round corners*/
    border-radius: 25px;
    height: 50px !important;
    /*cool option borders.*/
    background-color: #ffffff;
  }
  .telegram-button {
    margin-top: 5px;
    background-color: #1682fb;
    width: 85% !important;
    border-radius: 25px;
  }
}
/*text stuff*/
.telegram-popup p {
  color: #000000;
  padding: 4px;
}

.telegram-button {
  background-color: #1682fb;
  width: 80%;
  border-radius: 25px;
}

.telegram-button:hover {
  background-color: #1080f5;
}

.telegram-button p {
  color: #ffffff;
  font-size: 15px;
  /*padding makes the link like a bubble*/
  padding: 10px;
}

.telegram-button-link:link {
  text-decoration: none;
}
.iframe-desktop {
  width: 970px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
.iframe-mobile {
  width: 350px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}
.icon-class {
  margin-left: 20px;
  margin-right: -15px;
}
.close-ion {
  margin-bottom: 20px;
}
.fixed_button {
  position: fixed;
  bottom: -1px;
}
.fixed_button2 {
  position: fixed;
  bottom: 15px;
  cursor: pointer;
}

.chat-us-button {
  background-color: #3664e5 !important;
  border-color: #3664e5 !important;
  border-radius: 25px;
  font-size: 20px;
  color: azure;
  padding-inline: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.login-button {
  position: fixed;
  height: 100px;
}
@media (max-width: 1024px) {
  img {
    border-style: none;
    border-radius: 10px;
  }
  .close-ion {
    margin-left: 120%;
    margin-bottom: 5px;
  }
}
img {
  border-style: none;
  /* border-radius: 10px; */
}
.card {
  border: none;
  margin-bottom: 2rem;
  border-radius: 0px;
}

@media (max-width: 1024px) {
  .img-fluid {
    max-width: 100% !important;
  }
  .nav-tabs .nav-link {
    padding: 0.2rem 0.4rem !important;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #868181;
    margin-inline: 2px;
    border-radius: 10px;
    /* padding: 2px; */
  }
  .alert-text {
    font-size: 8px;
  }
}
@media (min-width: 1024px) {
  .nav-tabs .nav-link {
    font-weight: 300;
    font-size: large;
    border: 1px solid #86818173;
    
  }
  .tab-left-end {
    border-top-left-radius: 25px !important;
  }
  .nav-tabs .nav-link.active, [dir] .nav-tabs .nav-item.show .nav-link {
    background-color: transparent;
    border-color: #3e42af #3e42af transparent !important;
}
.nav-tabs .nav-link:after {
    background: #3e42af #3e42af transparent !important;
}
}
/* .nav-tabs .nav-link {
    font-weight: 600;
    font-size: large;
} */
</style>

<style lang="scss" scoped>
.translucent-wrap {
  height: 110px;
  position: relative;
}
@font-face {
  // font-family: "icomoon";
  // src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?3qkin2");
  // src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?#iefix3qkin2")
  //     format("embedded-opentype"),
  //   url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.woff?3qkin2")
  //     format("woff"),
  //   url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.ttf?3qkin2")
  //     format("truetype"),
  //   url("fhttps://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.svg?3qkin2#icomoon")
  //     format("svg");
  // font-weight: normal;
  // font-style: normal;
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

/* Extra stuff just to make the demo pretty. */
body {
  padding: 35px 0;
  color: #fff;
  font-family: "Lora", serif;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  background: #4183c4
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/noise-bg.png) repeat 0 0;
}

h1 {
  margin-bottom: 0px;
  font-size: 55px;
  line-height: 1;
}

h2 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 45px;
  font-size: 12px;
  text-transform: uppercase;
}

footer {
  margin: 75px 0 35px;
}

a {
  color: #4183c4;
  text-decoration: none;

  &:hover {
    color: lighten(#4183c4, 15%);
  }
}
</style>
