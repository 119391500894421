<template>
  <b-row class="banner-margin">
    <div class="d-flex justify-content-center mb-2 col-12 text-center">
      <!-- <iframe
        data-aa="1706423"
        src="//acceptable.a-ads.com/1706423"
        scrolling="no"
        style="
          border: 0px;
          padding: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        "
        allowtransparency="true"
      ></iframe> -->
      <iframe
        data-aa="1717027"
        src="//ad.a-ads.com/1717027?size=970x90"
        :class="!is_mobilesize ? 'iframe-desktop' : 'iframe-mobile'"
      ></iframe>
    </div>
  </b-row>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { BRow } from "bootstrap-vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
  },
};
</script>

<style>
.iframe-desktop {
  width: 970px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  margin-top: 15px;
}
.iframe-mobile {
  width: 350px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  
}
</style>